/**
 * 顶部bar
 */
import React, { PureComponent } from "react";
import styled from "styled-components";
import { Icon, Badge, Avatar, Dropdown, Menu, Button } from "antd";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import moment from "moment";
import { FaultCategory } from "@36node-mekong/sdk-ts";

import VehiclesSearchTree from "./search-tree";
import IconFont from "../components/iconfont";
import { globalActions } from "../actions";
import { globalSelectors } from "../selectors";
import {
  ROOT_NAMESPACE,
  WEATHER_LOCATION,
  ALERT_SUMMARY_IN_MS,
} from "../config";
import { ZIndex, USER_ID, SESSION_ID } from "../constants";
import { mekong } from "../sdk";

@withRouter
@connect(state => {
  const weather = globalSelectors.weather(state).result || {};
  const summary = globalSelectors.getAlertSummary(state).result || [];
  const session = globalSelectors.session(state).result || { user: {} };
  const stationAlertCount =
    globalSelectors.getStationAlertSummary(state).total || 0;

  const alertsCount = summary.reduce((acc, cur) => acc + cur.count, 0);

  const temperature = weather.temperature || "--";
  const weatherCode = weather.code || "";

  return {
    alertsCount,
    stationAlertCount,
    temperature,
    weatherCode,
    session,
    keyword: globalSelectors.keywordAssignSelector(state).assign,
    focusSearch: globalSelectors.toggleSelector(state).toggle,
  };
})
export default class TopBar extends PureComponent {
  globalEventAdded = true;
  componentWillMount() {
    // this.getWeacher();
    this.fetchSummary();
    this.fetchStationAlertSummary();

    this.props.dispatch(
      globalActions.alertSummaryCron.start(ALERT_SUMMARY_IN_MS)
    );
  }

  componentWillUnmount() {
    // stop summay cron
    this.props.dispatch(globalActions.alertSummaryCron.stop());
  }

  fetchSummary = () => {
    this.props.dispatch(
      globalActions.getAlertSummary.request({
        mute: false,
        category: FaultCategory.VEHICLE,
        // status: FaultRecordStatus.BROKEN, https://github.com/36node-mekong/bus-admin-pudongbus/issues/682
        _group: ["level"],
      })
    );
  };

  fetchStationAlertSummary = () => {
    this.props.dispatch(
      globalActions.getStationAlertSummary.request({
        _limit: 1,
        _offset: 0,
        stopAbnormal: true,
        chargeEndAt_gte: moment()
          .startOf("day")
          .toISOString(),
      })
    );
  };

  getWeacher() {
    // 当前使用的是bus-core中的api，api只能获取当天天数据，必须的date字段是为了兼容@36node/weather-sdk库
    const query = {
      filter: {
        location: WEATHER_LOCATION,
      },
    };
    this.props.dispatch(
      globalActions.getWeather.request({
        date: moment().format("YYYY-MM-DD"),
        query,
      })
    );
  }

  formatDate() {
    const now = moment();

    return `${now.format("M月DD日 ddd")}`;
  }

  onClickAlarmBadge = () => {
    const { history } = this.props;
    history.push("/monitor-table/alert");
  };

  onClickStationBadge = () => {
    const { history } = this.props;
    history.push("/monitor-table/station-alert");
  };

  get cursorStyle() {
    return {
      cursor: "pointer",
    };
  }

  get weatherIcon() {
    let { weatherCode } = this.props;
    weatherCode = Number.parseInt(weatherCode);
    if (Number.isSafeInteger(weatherCode)) {
      if (weatherCode >= 0 && weatherCode <= 3) {
        return "iconqing-tianqi";
      } else if (weatherCode >= 4 && weatherCode <= 9) {
        return "iconduoyun";
      } else if (weatherCode >= 10 && weatherCode <= 18) {
        return "iconyu";
      } else if (weatherCode >= 19 && weatherCode <= 25) {
        return "iconxue";
      } else {
        return "iconqing-tianqi";
      }
    }
    return "iconqing-tianqi";
  }

  toggleSearchFocusOn = () => {
    if (!this.props.focusSearch) {
      this.props.dispatch(globalActions.toggleActions.open());
    }
  };

  onClickLogout = () => {
    mekong
      .createOperation({
        body: {
          name: "logout",
          method: "DELETE",
          path: "/auth/v1/sessions",
          user: sessionStorage.getItem(USER_ID),
          domain: "session",
          params: { session: localStorage.getItem(SESSION_ID) },
        },
      })
      .then(() => {
        this.props.dispatch(
          globalActions.logout.request({
            sessionId: localStorage.getItem(SESSION_ID),
          })
        );
      });
  };

  /* eslint-disable */
  UserMenu = (
    <Menu onClick={this.onClickLogout}>
      <Menu.Item>
        <a href="#">退出登陆</a>
      </Menu.Item>
    </Menu>
  );
  /* eslint-disable */

  render() {
    const { session = {}, focusSearch } = this.props;
    const { user = {} } = session;

    if (
      user.ns &&
      !(user.ns.includes(ROOT_NAMESPACE) || ROOT_NAMESPACE.includes(user.ns))
    ) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <Box
        onClick={() => {
          this.props.dispatch(globalActions.toggleActions.close());
        }}
      >
        <Left
          onClick={e => {
            // 阻止冒泡
            e.stopPropagation();
          }}
        >
          <SearchTreeCon
            className={`${focusSearch ? "focus" : ""}`}
            top={128}
            onClick={this.toggleSearchFocusOn}
          >
            <VehiclesSearchTree />
          </SearchTreeCon>
        </Left>
        <Right>
          <StationBadge
            count={this.props.stationAlertCount}
            onClick={this.onClickStationBadge}
            style={this.cursorStyle}
          >
            <IconFont
              style={this.cursorStyle}
              type="iconicon_chongdianjijiankong"
            />
          </StationBadge>
          <AlarmBadge
            count={this.props.alertsCount}
            onClick={this.onClickAlarmBadge}
            style={this.cursorStyle}
          >
            <IconFont style={this.cursorStyle} type="iconbus" />
          </AlarmBadge>

          <Date>{this.formatDate()}</Date>

          {/* <Weather>
            <IconFont type={this.weatherIcon} />
            <span>{this.props.temperature}℃</span>
          </Weather> */}

          <UserArea>
            {user.avatar && <Avatar src={user.avatar} size="small" />}
            {!user.avatar && (
              <IconFont type="iconyonghu" className="icon-avatar" />
            )}
            <Dropdown overlay={this.UserMenu} trigger={["click"]}>
              <Button className="user-name">
                {user.nickname || user.username}
                <Icon type={"down"} />
              </Button>
            </Dropdown>
          </UserArea>
        </Right>
      </Box>
    );
  }
}

const UserArea = styled.div`
  margin-left: 24px;
  color: #a2a7bb;
  display: flex;
  align-items: center;

  .ant-btn {
    border: 0;
    padding: 0 8px;
    transform: translate(0, 2px);
    background: #303c77;
    i {
      margin-left: 0;
      color: white;
    }
  }

  .ant-btn:focus {
    background: #303c77;
    color: #a2a7bb;
  }

  .ant-btn:hover {
    color: white;
  }

  .icon-avatar {
    font-size: 24px;
  }

  .user-name {
    color: #a2a7bb;
  }
`;

// const Weather = styled.div`
//   margin-left: 16px;
//   font-size: 14px;

//   transform: translate(0, 3px);
// `;

const Date = styled.div`
  margin-left: 16px;

  transform: translate(0, 3px);
`;

const AlarmBadge = styled(Badge)`
  i {
    color: ${props => (props.count ? "red" : "grey")};
    font-size: 17px;
    padding: 8px;
  }
`;

const StationBadge = styled(Badge)`
  i {
    color: ${props => (props.count ? "red" : "grey")};
    font-size: 14px;
    padding: 10px;
  }
`;

const Box = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
  background: rgba(48, 60, 119, 1);
  box-shadow: 0px 3px 2px 0px rgba(14, 14, 25, 0.12);
`;

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 250px;
  height: 100%;
  position: relative;
`;

const SearchTreeCon = styled.div`
  width: 250px;
  position: absolute;
  top: 7px;
  z-index: ${ZIndex.searchTreeCon};
  background-color: rgab(255, 255, 255, 0);
  overflow: hidden;
  transition: bottom 0.1s, width 0.1s, background 0.1s;

  .ant-tree {
    display: none;
  }

  .search-result {
    display: none;
  }

  .ant-input {
    color: white;
    background: rgba(39, 51, 108, 1);
    border: none;

    &::placeholder {
      color: #545d8a;
    }
  }

  .anticon-search {
    color: white;

    &:hover {
      color: white;
    }
  }

  &.focus {
    top: 7px;
    max-height: 100vh;
    background: rgba(39, 51, 108, 1);
    box-shadow: 2px 2px 4px 0px rgba(204, 204, 204, 0.5);

    .ant-tree {
      display: block;
      max-height: 100vh;
    }

    .search-result {
      width: 100%;
      display: block;
      color: white;
      height: 36px;
      display: flex;
      background: #1a2558;
      align-items: center;
      padding: 0 24px;
    }
  }
  &.selected {
    width: 0;
    padding: 0;
  }
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #a2a7bb;

  .ant-badge-count {
    min-width: 17px;
    line-height: 17px;
    height: 17px;
  }

  .ant-btn:hover {
    background: none;
  }
`;
